/**
 * 微信小程序授权 scope 枚举类型
 * @readonly
 * @enum {string}
 * @property {string} BASE - 微信小程序隐式授权
 * @property {string} USER_INFO - 微信小程序显示授权
 */
export const WeappAuthScope = {
  BASE: 'base',
  USER_INFO: 'userinfo',
};
