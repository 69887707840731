import { request } from './utils/request';
import { isObject, isWeapp, isFunction } from './utils/lang';
import { getLocalISOString } from './utils/date';
import { uuid } from './utils/string';
import sessionStorage from './utils/sessionStorage';
import { getPlatform } from './utils/platform';
import Member from './Member';
import DebounceBuffer from './utils/DebounceBuffer';

/**
 * 客户事件类
 */
class MemberEvent {
  /**
   * 创建 MemberEvent 实例
   */
  constructor() {
    this._member = new Member();
    this._buffer = new DebounceBuffer(this.report.bind(this));
    this._pvKey = 'mai_page_view_key';

    if (!isWeapp) {
      window.addEventListener('beforeunload', () => {
        const buffer = sessionStorage.getItem(this._pvKey) || [];
        if (buffer.length) {
          const preBuffer = buffer[0];
          MemberEvent.setDuration(preBuffer);
          this.report([preBuffer]);
        }
      });
    }
  }

  /**
   * 发 ajax 上报客户事件
   *
   * @private
   * @param {array} logs - 客户事件缓存数组
   * @returns {promise} 发送请求后的回调
   */
  report(logs) {
    return request.post('/v2/memberEventLogs', { clientId: this._member.clientId, logs });
  }

  /**
   * 记录客户事件
   *
   * @param {string} name - 事件名称，需与群脉环境和账号下面的事件名匹配
   * @param {object} properties - 事件属性，需与事件名称下的属性匹配，可以在客户行为记录中展示出来
   * @param {function} cb - 回调函数，在记录客户事件成功或者失败后被调用
   */
  log(name, properties, cb) {
    MemberEvent.validate(name, properties, cb);
    const mergedProperties = { ...properties };
    const data = MemberEvent.getLog(name, mergedProperties);
    this._buffer.debounce(data, cb);
  }

  /**
   * 上报 Page View 事件
   *
   * @param {object} [properties={}] - 事件属性
   * @param [properties.utmCampaign] {string} - 可选，访问来源所关联的活动
   * @param [properties.utmContent] {string} - 可选，访问来源内容
   * @param [properties.utmTerm] {string} - 可选，访问来源搜索关键词
   * @param [properties.utmMedium] {string} - 可选，访问来源链接类型
   * @param [properties.utmSource] {string} - 可选，访问来源
   * @param [properties.content] {string} - 可选，当前页面内容，由 SDK 自动添加（取页面标题）
   * @param [properties.url] {string} - 可选，当前页面地址，由 SDK 自动添加
   * @param [properties.refererContent] {string} - 可选，前一页内容，由 SDK 自动添加（上一次记录的）
   * @param [properties.refererUrl] {string} - 可选，要跳转到的页面内容
   * @param [properties.redirectContent] {string} - 可选，要跳转到的页面地址
   * @param [properties.redirectUrl] {string} - 可选，要跳转到的页面地址
   * @param [properties.extra] {object} - 可选，额外信息
   * @param {function} cb - 回调函数
  */
  logPageView(properties = {}, cb) {
    if (cb && !isFunction(cb)) {
      throw new Error('The cb parameter should be a function');
    }
    const mergedProperties = { ...getPlatform(), ...properties };
    if (isObject(properties.extra)) {
      properties.extra = JSON.stringify(properties.extra);
    }

    const buffer = sessionStorage.getItem(this._pvKey) || [];
    const data = MemberEvent.getLog('maievent-page-view', mergedProperties);
    const preBuffer = buffer.shift();
    buffer.push(data);

    if (preBuffer) {
      const preBufferCopy = { ...preBuffer };
      MemberEvent.setDuration(preBufferCopy);
      this._buffer.debounce(preBufferCopy);

      const curProperties = JSON.parse(data.properties);
      const preProperties = JSON.parse(preBuffer.properties);
      curProperties.refererContent = preProperties.content;
      curProperties.refererUrl = preProperties.url;
      data.properties = JSON.stringify(curProperties);
    }
    sessionStorage.setItem(this._pvKey, buffer);
    this._buffer.debounce(data, cb);
  }

  /**
   * 上报 Page Operate 事件
   *
   * @param {object} [properties={}] - 事件属性
   * @param [properties.utmSource] {string} - 可选，访问来源
   * @param [properties.utmContent] {string} - 可选，访问来源内容
   * @param [properties.utmMedium] {string} - 可选，访问来源链接类型
   * @param [properties.utmCampaign] {string} - 可选，访问来源所关联的活动
   * @param [properties.utmTerm] {string} - 可选，访问来源搜索关键词
   * @param [properties.pageContent] {string} - 可选，当前页面内容，由 SDK 自动添加（取页面标题）
   * @param [properties.url] {string} - 可选，当前页面地址，由 SDK 自动添加
   * @param properties.type {string} - 操作的类型，可选值：click、tap、swipe、scroll、choose、input 或自定义
   * @param properties.content {string} - 操作的内容
   * @param [properties.redirectContent] {string} - 可选，操作后要跳转到的页面内容
   * @param [properties.redirectUrl] {string} - 可选，操作后要跳转到的页面地址
   * @param [properties.extra] {object} - 可选，额外信息
   * @param {function} cb - 回调函数
   */
  logPageOperate(properties = {}, cb) {
    if (cb && !isFunction(cb)) {
      throw new Error('The cb parameter should be a function');
    }
    const { url, content } = getPlatform();
    const mergedProperties = {
      url, pageContent: content, ...properties,
    };

    if (isObject(properties.extra)) {
      properties.extra = JSON.stringify(properties.extra);
    }

    const data = MemberEvent.getLog('maievent-page-operate', mergedProperties);
    this._buffer.debounce(data, cb);
  }

  /**
   * 获取客户事件对象
   *
   * @private
   * @param {string} name - 事件名称
   * @param {object} properties - 事件属性
   * @returns {object} 客户事件对象
   */
  static getLog(name, properties) {
    return {
      name,
      properties: JSON.stringify(properties),
      id: uuid(),
      occurredAt: getLocalISOString(),
    };
  }

  /**
   * 验证参数类型
   *
   * @private
   * @param {string} name - 事件名称
   * @param {object} properties - 事件属性
   * @param {function} cb - 回调函数
   */
  static validate(name, properties, cb) {
    if (!name) {
      throw new Error('name is required');
    }
    if (properties && !isObject(properties)) {
      throw new Error('The properties parameter should be a key-value object');
    }
    if (cb && !isFunction(cb)) {
      throw new Error('The cb parameter should be a function');
    }
  }

  /**
   * 设置 Page View 前一页停留时间
   *
   * @private
   * @param {object} preBuffer - 前一页客户事件的缓存
   */
  static setDuration(preBuffer) {
    const durationInSeconds = parseInt((+new Date() - +new Date(preBuffer.occurredAt)) / 1000, 10);
    preBuffer.properties = JSON.stringify({ durationInSeconds });
  }
}

export default MemberEvent;
