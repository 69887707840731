import UAParser from 'ua-parser-js';
import { isWeapp } from './lang';

const platform = {
  sdk: 'js', // SDK 类型
  sdkVersion: process.env.SDK_VERSION, // maijs 版本
};

if (isWeapp) {
  const res = wx.getSystemInfoSync();
  const [os, osVersion] = res.system.split(' ');
  Object.assign(platform, {
    screenWidth: res.screenWidth,
    screenHeight: res.screenHeight,
    manufacturer: res.brand,
    model: res.model,
    os,
    osVersion,
  });
} else {
  const { userAgent } = window.navigator;
  const { device, os, browser } = UAParser(userAgent);
  Object.assign(platform, {
    userAgent, // 用户代理字符串
    screenWidth: window.screen.width, // 屏幕宽度
    screenHeight: window.screen.height, // 屏幕高度
    manufacturer: device.vendor, // 设备制造商
    model: device.model, // 设备型号
    os: os.name, // 操作系统
    osVersion: os.version, // 操作系统版本
    browser: browser.name, // 浏览器
    browserVersion: browser.version, // 浏览器版本
  });
}

const getPlatform = () => {
  if (isWeapp) {
    const pages = getCurrentPages();
    const url = pages.length ? pages[pages.length - 1].route : '';
    platform.url = url;
  } else {
    platform.url = window.location.href;
    platform.content = document.title;
  }
  return platform;
};

export {
  getPlatform,
};
